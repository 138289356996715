body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: 'PT Sans', 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(248, 245, 240); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: rgb(15, 68, 92);
}

h1 {
  color: steelblue;
  font-family: 'PT Sans Caption', 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* .navbar {
  display: flex;
} */

.navlinks {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f3f3f3;
  /* position: fixed; */
  top: 0;
  width: 100%;
  border: 1px solid #e7e7e7;
  font-weight: 500;

}

.navelement {
  float: left;
  font-family: 'PT Sans Caption', 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
}

.navelement a {
  display: block;
  color: #666;
  padding: 10px 16px;
  text-decoration: none;
}

.navelement a:hover {
  background-color: #555;
  color: white;
}

.pagecontent {
  /* position: fixed; */
  top: 24px;
}